




















import { Component, Prop, Vue } from 'vue-property-decorator';
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import uuid from 'uuid/v4';
import { MimeType } from '@/modules/fileManagement/types';

@Component({
  components: {
    VueDropzone
  }
})
export default class AppDropzone extends Vue {
  loading: boolean = false;

  @Prop({ type: String, required: true }) url!: string;
  @Prop({ type: Object, default: () => ({}) }) additional!: { [key: string]: any };
  @Prop({ type: String, default: 'file' }) fileParam!: string;
  @Prop({ type: String, default: 'auto' }) height!: string;
  @Prop({ type: Number, default: null }) maxFiles!: number | null;

  get options (): object {
    return {
      createImageThumbnails: true,
      url: this.url,
      acceptedFiles: [
        MimeType.PNG,
        MimeType.JPG,
        MimeType.PDF,
        MimeType.WORD_DOC,
        MimeType.WORD_DOCX,
        MimeType.EXCEL_XLSX,
        MimeType.EXCEL_XLT,
        MimeType.ODT,
      ].join(','),
      maxFiles: this.maxFiles,
      timeout: 3600000,
      parallelUploads: 1,
      paramName: this.fileParam,
      dictInvalidFileType: this.$t('common.fileError'),
      dictMaxFilesExceeded: this.$t('common.maxFilesExceeded'),
      headers: {
        'authorization': `Bearer ${localStorage.getItem('default_auth_token')}`
      }
    }
  }

  get dropZone(): string {
    return uuid();
  }

  beforeSending(file: File, xhr: XMLHttpRequest, formData: FormData) {
    this.loading = true;

    Object.keys(this.additional).forEach((key: string) =>
      formData.append(key, this.additional[key])
    );

    formData.append('filename', file.name);
    formData.append('file_id', uuid());
  }

  finishUpload() {
    if (!this.loading) {
      return;
    }

    this.$emit('success');
    this.loading = false;
  }

  maxFilesExceeded() {
    this.$emit('error', { response: { status: 280 } })
  }

  uploadError() {
    this.$emit('error', { response: { status: 281 } })
  }

  created() {
    this.$emit('update:close', () => {
      // @ts-ignore
      this.$refs.dropzone && this.$refs.dropzone.removeAllFiles();
    })
  }
};
